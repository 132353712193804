//
// Table actions
//

.table-action {
  font-size: $font-size-sm;
  color: $table-action-color;
  margin: 0.25rem;

  &:hover {
    color: darken($table-action-color, 10%);
  }
}

.table-action-delete {
  &:hover {
    color: theme-color("danger");
  }
}

.table-action-info {
  &:hover {
    color: theme-color("info");
  }
}

.table-action-warning {
  &:hover {
    color: theme-color("warning");
  }
}

.table-action-success {
  &:hover {
    color: theme-color("success");
  }
}

//To change the color on hover for resend button on sub-user section
.table-action-resend {
  &:hover {
    color: theme-color("info");
  }
}

.table-dark {
  .table-action {
    color: $table-dark-action-color;
  }
}

// To change the color on hover for email config to make it primary connection
.table-action-primary-email {
  &:hover {
    color: theme-color("info");
  }
}
